<template>
  <div id="alertcard">
    <v-alert id="alertcard" v-for="(value, key) in sortedAlerts" :key="key">
      <h3>
        <v-icon class="icon" color="red">mdi-calendar</v-icon>
        {{ key }}
      </h3>
      <v-alert
        v-for="(alert, i) in value"
        :key="`${i}+${alert}`"
        elevation="1"
      
      >
        <v-icon class="icon" color="red">mdi-alert-outline</v-icon>
        {{ alert }}
      </v-alert>
      <v-alert v-if="value.length == 0">
        <h3>There are no alerts for this day</h3>
      </v-alert>
    </v-alert>
  </div>
</template>

 
<script>
//import alerts1 from '@/data/alerts1.json';

export default {
  name: 'alerts',
  data: () => ({
    alerts: [],
    sortedAlerts: [],
  }),
  methods: {
    sortByDate() {
      let dates = this.alerts;
      this.sortedAlerts = Object.keys(dates)
        .sort((a, b) => new Date(b) - new Date(a))
        .reduce(function (result, key) {
          result[key] = dates[key];
          return result;
        }, {});
    },
    fetchAlerts() {
      // this.$store
      //   .dispatch("DDB_GET_EVENT_DATA")
      //   .then(() => this.populateAlerts());
    },
    populateAlerts() {
      this.alerts = this.$store.getters.getAlerts;
      this.sortByDate();
    },
  },
  mounted() {
    this.fetchAlerts();
  },
};
</script>

<style lang="css" scoped>
.icon {
  margin-right: 4px;
}
</style>

